import i18next from "i18next";

export function getHeaders({
  token = null,
  contentType = "application/json",
  omitContentType = false,
}: {
  token?: string | null;
  contentType?: string;
  omitContentType?: boolean;
} = {}): Record<string, string> {
  const headers: Record<string, string> = {
    "Accept-Language": i18next.language,
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
  };

  // Conditionally add the Content-Type header
  if (!omitContentType) {
    headers["Content-Type"] = contentType;
  }

  return headers;
}
