import * as Sentry from "@sentry/react";
import { QueryKey } from "react-query";

import { useAuthentication } from "Context/AuthenticationContext";
import { API_ENDPOINT, SENTRY_DSN } from "Settings";
import { getHeaders } from "Utils/Api";

export function getEndpoint(path: string): string {
  if (path.startsWith("http://") || path.startsWith("https://")) {
    return path;
  }
  return API_ENDPOINT + path;
}

export default function useApi() {
  const { token, logout } = useAuthentication();

  /**
   * Raw fetch wrapper, embedding authentication and common parameters into init.
   *
   * @param path
   * @param init
   */
  function _fetch(path: string, init?: RequestInit, omitContentType?: boolean) {
    return new Promise<Response>((resolve, reject) =>
      fetch(getEndpoint(path), {
        ...(init || {}),
        headers: {
          ...getHeaders({ token: token, omitContentType: omitContentType }),
        },
      }).then((res: Response) => {
        if (res.ok) {
          resolve(res);
        } else {
          if (res.status === 401) {
            logout();
          } else if (res.status === 404 && SENTRY_DSN) {
            Sentry.captureMessage(`404 - ${path}`, {
              level: "warning",
            });
          }
          reject(res);
        }
      }),
    );
  }

  /**
   * Wrapper for http get requests.
   *
   * @param path
   * @param init
   */
  function get(path, init?: RequestInit) {
    return _fetch(path, { ...(init || {}), method: "GET" });
  }

  /**
   * Wrapper for http post requests.
   *
   * @param path
   * @param init
   */
  function post(path, init?: RequestInit, omitContentType?: boolean) {
    return _fetch(path, { ...(init || {}), method: "POST" }, omitContentType);
  }

  /**
   * Wrapper for http patch requests.
   *
   * @param path
   * @param init
   */
  function patch(path, init?: RequestInit) {
    return _fetch(path, { ...(init || {}), method: "PATCH" });
  }

  /**
   * Wrapper for http put requests.
   *
   * @param path
   * @param init
   */
  function put(path, init?: RequestInit) {
    return _fetch(path, { ...(init || {}), method: "PUT" });
  }

  /**
   * Wrapper for http delete requests.
   *
   * @param path
   * @param init
   */
  function del(path, init?: RequestInit) {
    return _fetch(path, { ...(init || {}), method: "DELETE" });
  }

  /**
   * Wrapper to embed token in query keys to forget about data if token changes.
   *
   * @param key
   */
  function getQueryKey(key: QueryKey): any[] {
    return [token, ...(typeof key === "string" ? [key] : key)] as any[];
  }

  return { fetch: _fetch, del, get, post, patch, put, getQueryKey };
}
